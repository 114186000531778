import * as React from "react"
import { graphql } from 'gatsby'
import { linkResolver } from '../../../utils/link_resolver'
import Seo from '../../../components/seo'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

const ThanksPage = ({data}) => {
  const entry = data.prismicThanksPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <div className="w-full bg-black rounded-br-[133px]">
        <div className="flex-none md:flex justify-between">
          <div className="w-full md:w-6/12">
            <GatsbyImage className="w-full h-full object-cover block py-12 md:py-0" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 px-16 py-16 md:py-36">
            <div className="font-poppins leading-snug text-2xl text-white font-semibold">
              <h2 className="mb-8 leading-tight">
                <span className="bg-white text-3xl font-bold text-orange px-3 py-1 inline-block">{entry.data.tag}</span><br />
              </h2>
              <p className="ml-3">{entry.data.heading}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full my-16">
        <div className="w-7/12 mx-auto max-w-screen-xl">
         <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
            <PrismicRichText linkResolver={linkResolver} field={entry.data.text.richText} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicThanksPage {
    data {
      meta_description
      page_title
      tag
      heading
      image {
        alt
        gatsbyImageData
      }
      text {
        richText
      }
    }
  }
}
`

export default ThanksPage
